.home-page-background {
  background-image: url('../../../public/images/prieuré.jpg');
  background-color: rgba(55, 55, 55, 0.9); /* Couleur rouge semi-transparente */
  background-blend-mode: multiply; /* Mélanger la couleur avec l'image de fond */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .home-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
